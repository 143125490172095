import { RemixBrowser } from "@remix-run/react";
import { posthog } from "posthog-js";
import { startTransition, StrictMode, useEffect } from "react";
import { hydrateRoot } from "react-dom/client";

import { POSTHOG_KEY } from "./config.js";

function PosthogInit() {
	useEffect(() => {
		if (POSTHOG_KEY) {
			posthog.init(POSTHOG_KEY, {
				api_host: "https://eu.i.posthog.com",
				person_profiles: "identified_only", // or 'always' to create profiles for anonymous users as well
				capture_pageview: false,
			});
		}
	}, []);

	return null;
}

startTransition(() => {
	hydrateRoot(
		document.getElementById("root")!,
		<StrictMode>
			<RemixBrowser />
			<PosthogInit />
		</StrictMode>,
	);
});
